@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-contextualized-help {
  &__activator {
    &.icon {
      opacity: 0.6;
    }
    &.text {
      cursor: help; // same interrogation symbol as mdi-help icon
    }
  }
  // Help Card
  &--card {
    &.v-card {
      padding: var(--dialog-padding);
      color: var(--color-text-theme);
      background-color: var(--color-element-layer3);
      opacity: 1;

      // BEM
      .v-card {
        &__title {
          font-size: var(--font-size-sm);
          font-weight: 700; /* Bold */
        }
        &__text {
          color: var(--color-text-theme);
        }
      }

      // if prop colorInvertedThemeAsTooltips = true
      &.color-inverted-theme {
        background-color: var(--color-inverted-container-theme);
        &,
        .v-card__text {
          color: var(--color-inverted-text-theme);
        }
      }
    }
  }
}
