@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.product-target-row,
.convert-factor-objective-container {
  .inline-fields {
    width: 100%;
  }
}

.product-target-row {
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.convert-factor-objective-container {
  display: flex;
  padding-bottom: var(--box-padding-admin);

  .objectives-list {
    flex-shrink: 0;
  }
}

.associated-pu-list {
  .pu-item {
    .legend-header {
      display: flex;

      .v-btn {
        margin-top: 0;
        margin-left: auto; // aligned to right
      }
    }
  }
}

// Unknowen containers. Could be obsolete (mDube, may 2022)
.product-tolerance-value {
  width: 45%;
}
